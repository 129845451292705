<template>
  <div class="login-wrapper">
    <SystemHead page-type="sign" />
    <div class="login-background"></div>
    <div class="login-body">
      <div class="form-signin">
        <h1>{{ $t("sign.sign_in") }}</h1>
        <el-form
          :model="loginData"
          ref="loginForm"
          :rules="rules"
          @keydown.enter.native="handleLogin('loginForm')"
          @submit.native.prevent
        >
          <el-form-item :label="$t('sign.username')" prop="username">
            <el-input
              v-model="loginData.username"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('sign.password')" prop="password">
            <el-input
              type="password"
              v-model="loginData.password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn-wrapper">
          <button
            class="login-button"
            type="submit"
            @click.prevent="handleLogin('loginForm')"
          >
            {{ $t("sign.sign_in") }}
          </button>
        </div>
        <!-- <div class="clearfix link-wrapper">
        <router-link to="/sign/register" class="float-left">{{
          $t("sign.create_account")
        }}</router-link>
        <router-link to="/sign/reset-password" class="float-right">{{
          $t("sign.forgot")
        }}</router-link>
      </div> -->

        <div class="forgot">
          <router-link :to="{ name: 'resetPassword' }">{{
            $t("sign.forgot")
          }}</router-link>
        </div>
      </div>
    </div>

    <footer class="footer mt-auto py-2 fixed-bottom text-center">
      <div class="container">
        <small class="d-block text-muted">
          &copy; 2019~{{ year }} Brainlabel.org. All rights reserved.
          <a
            href="//beian.miit.gov.cn"
            target="_blank"
            class="text-muted"
            style="font-size: 12px"
            >粤ICP备16059280号</a
          ></small
        >
      </div>
    </footer>
  </div>
</template>

<script>
import logo from "@/assets/logo.png";
import { mapState, mapGetters } from "vuex";
import { MessageBox, Loading } from "element-ui";
import SystemHead from "../layouts/systemHead";
import { login } from "../api/api";
import { eventHub } from "@/eventHub.js";
import { send_request_get } from "../utils/utils.js";
export default {
  components: {
    SystemHead,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      logo: logo,
      loginData: {
        username: "",
        password: "",
        type: "login",
      },
    };
  },
  computed: {
    ...mapState({
      license_request: (state) => state.license_request,
      backendConfig: (state) => state.backendConfig,
    }),
    rules() {
      var checkUsername = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t("validate.login_username")));
        } else {
          callback();
        }
      };
      var checkPassword = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t("validate.login_password")));
        } else {
          callback();
        }
      };
      return {
        username: [{ validator: checkUsername, trigger: "blur" }],
        password: [{ validator: checkPassword, trigger: "blur" }],
      };
    },
  },

  beforeCreate() {},
  created() {},
  mounted() {
    eventHub.$emit("closeProcessList");
  },
  methods: {
    handleLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleSubmit();
        } else {
          return false;
        }
      });
    },
    async handleSubmit() {
      let submitData = { ...this.loginData };
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        //license_request: true,
        // if (this.license_request) {
        //   //字符串中提取ip地址
        //   var reg = /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/;
        //   var iparray = this.backendConfig.backend_url.match(reg);
        //   if (iparray && iparray[0]) {
        //     let license_url = "http://" + iparray[0] + ":6677/check_lc";

        //     let requestLicense = send_request_get(license_url);
        //     console.log("ipaddress", iparray, requestLicense);
        //   }
        // }

        let res = await login(submitData);
        if (res.status === 200) {
          if (res.data) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.userinfo));
            localStorage.setItem(
              "user_rights",
              JSON.stringify(res.data.department_limits)
            );
            this.$store.dispatch("setUserIdAsync", res.data.userinfo.id);
            this.$store.dispatch(
              "setUserNameAsync",
              res.data.userinfo.username
            );
            if (res.data.expriy_notify && res.data.expriy_notify != "")
              this.$helper.warnNotify(res.data.expriy_notify);
            //this.$router.push("/summary");
            eventHub.$emit("getProcessList", false);
            loadingInstance.close();
            if (
              res.data.userinfo.role === "0" ||
              res.data.userinfo.role === "1"
            ) {
              await this.$router.push("/summary");
            } else {
              await this.$router.push("/data-manage/list");
            }
          }
        } else {
          this.$helper.errNotify(this.$t("login_failure"), res.message);
          loadingInstance.close();
        }
      } catch (error) {
        this.$helper.errNotify(this.$t("login_failure"), error.message);
        loadingInstance.close();
      }
    },
  },
};
</script>
<style scoped>
body {
  background: #eeedfb;
}
</style>
<style lang="less" >
.login-wrapper {
  background: #eeedfb;
  height: 100vh;
  position: relative;
}
.login-background {
  position: absolute;
  width: 935px;
  height: 657px;
  left: 7%;
  top: 20%;
  background: #eeedfb url("../assets/login.png") no-repeat center center;
  background-size: contain;
}

.login-body {
  position: absolute;
  top: 13%;
  left: 52%;
  right: 0;
  width: 550px;
  height: 796px;
  overflow: hidden;
  background: #fff;
  box-shadow: 5px 5px 5px #dadada;
}
.container {
  max-width: 960px;
}
.site-header {
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  a {
    color: #999;
    transition: ease-in-out color 0.15s;
  }
  a:hover {
    color: #fff;
    text-decoration: none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-signin {
  padding: 120px 87px 0;
  h1 {
    color: #397cf7;
    font-size: 40px;
    margin-bottom: 90px;
  }

  label.el-form-item__label {
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    color: #666666;
    margin-bottom: 16px;
  }
  input.el-input__inner {
    border: none;
    border-bottom: 1px solid #bab8ba;
    font-size: 25px;
    padding: 0;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #353a40 !important;
    transition: background-color 5000s ease-in-out 0s;
    background: none;
  }

  .checkbox {
    font-weight: 400;
  }
  .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-control:focus {
    z-index: 2;
  }
  .btn-wrapper {
    margin: 0 0 10 0;
  }
  .link-wrapper {
    padding: 10px 0;
  }
  .login-button {
    width: 240px;
    height: 60px;
    background: #397cf7;
    line-height: 60px;
    text-align: center;
    font-size: 24px;
    color: #fff;
    border-radius: 30px;
    border: none;
    margin-top: 100px;
  }
  .login-button:hover {
    background: #2c71f1;
  }
  .login-button:focus {
    background: #1f60d8;
  }
  button:focus {
    outline: 0;
  }
  .forgot {
    text-align: right;
    margin: 100px 0 0 0;
  }
}

.logo {
  max-height: 80px;
  height: 80px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-signup {
  max-width: 700px;
}
</style>
